@import '../../assets/styles';

.how-it-work {
    padding: 60px 0;
    background-image: url("../../assets/images/how-it-works-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: $color-white;
  
    &__header {
      margin-bottom: 53px;
      color: $color-white;
    }
  
    &__wrapper {
      &.steps {
        display: flex;
        margin-bottom: 32px;
  
        .step {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          overflow: hidden;
  
          &:not(:last-child) {
            padding-right: 58px;
          }

          &:last-child {
            .step__round{
              background-color: #007c40;
            }
          }

          &__round {
            position: relative;
            border: 1px solid $color-white;
            border-radius: 50%;
            width: 98px;
            height: 98px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 28px;
  
            &:before,
            &:after {
              content: '';
              position: absolute;
              border: 1px solid $color-white;
              height: 1px;
              width: 200px;
            }
  
            &:before {
              left: 0;
              transform: translateX(-100%);
            }
  
            &:after {
              left: 100%;
            }
  
            &__number {
              position: absolute;
              background-color: $color-primary;
              border: 2px solid $color-white;
              font-size: 18px;
              font-weight: 900;
              line-height: 47px;
              height: 28px;
              width: 28px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              top: 1px;
              left: 4px;
            }
  
            &__triple {
              position: absolute;
              right: 50%;
              transform: translateX(50%) translateY(100%);
              bottom: 0;
              width: 0;
              height: 0;
              margin: 0 auto;
              border-top: 7px solid $color-white;
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
            }
  
            img {
              max-width: 80px;
              max-height: 65px;
            }
          }
  
          &__title {
            font-size: 13px;
            line-height: 15px;
            font-weight: 300;
            text-align: center;
            max-width: 190px;
          }
          
          &:first-child {
            .step__round {
              &:before {
                content: none;
              }
            }
          }
  
          &:last-child {
            .step__round {
              &:after {
                content: none;
              }
            }
          }
        }
      }
    }
  
    &__button {
      display: flex;
      width: min-content;
      margin: 0 auto;
      white-space: nowrap;
    }
  }
  
  @media screen and (max-width: $tablet) {
    .how-it-work {
      padding: 85px 0 115px;
  
      &__header {
        margin-bottom: 60px;
      }
  
      &__wrapper {
        &.steps {
          flex-direction: column;
          margin-bottom: 0;
  
          .step {
            &:not(:last-child) {
              padding-right: initial;
              margin-bottom: 70px;
            }
  
            &__round {
              &:before,
              &:after {
                content: none;
              }
            }
  
            &__title {
              max-width: 615px;
              font-size: 26px;
              line-height: 30px;
              font-weight: 300;
            }
          }
        }
      }
     
  
      &__button {
        display: none;
      }
    }
  }
  
  @media screen and (min-width: $mobile) and (max-width: $tablet) {
//   @include screen-between($mobile, $tablet) {
    .how-it-work {
      &__wrapper {
        &.steps {
          .step {
            &__round {
              width: 190px;
              height: 190px;
              border: 2px solid $color-white;
  
              &__number {
                font-size: 36px;
                font-weight: 900;
                line-height: 94px;
                height: 54px;
                width: 54px;
                top: 1px;
                left: 4px;
              }
  
              &__triple {
                border-top: 16px solid $color-white;
                border-left: 16px solid transparent;
                border-right: 16px solid transparent;
              }
  
              img {
                transform: scale(2);
              }
            }
          }
        }
      }
    }
  }
  
  @media screen and (max-width: $mobile) {
    .how-it-work {
      &__header {
        margin-bottom: 32px;
      }
      
      &__wrapper {
        &.steps {
          .step {
            &:not(:last-child) {
              margin-bottom: 50px;
            }
            
            &__title {
              font-size: 14px;
              line-height: 1.2;
              font-weight: 300;
              max-width: 92%;
            }
          }
        }
      }
    }
  }