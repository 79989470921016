@import '../../assets/styles';

.selectorList {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    max-width: 1224px;
    margin: auto;

    &__container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 5px;
        width: 100%;
        margin-bottom: 45px;
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s ease-in-out;
        margin: 8px;
        width: 15%;
        padding: 15px 10px;
        border-radius: 8px;
        background: #efefef;
        cursor: pointer;
        text-align: center;
        list-style: none;

        &:not(.selected) {
            color: gray
        }

        &:hover {
            transition: 0.2s ease-in-out;
            box-shadow: 0px 0px 32px 0px rgba(190, 190, 190, 0.8);
            background: $color-primary;
            color: $color-white;
        }

    }

    .selected {
        background: $color-primary;
        color: $color-white;
    }
}

@media screen and (max-width:$tablet) {
    .selectorList {

        &__item {
            min-width: 30%;
        }
    }
}

@media screen and (max-width:$mobile) {
    .selectorList {
        &__item {
            gap: 0;
            padding: 15px;
            min-width: 40%;
        }
    }
}