.scanner-container {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  color: #4a4a49;
  display: flex;
  align-items: center;
  justify-content: center;

  .scanner {
    position: relative;
    margin: 8px;

    .scanner__close {
      position: absolute;
      z-index: 10;
      right: 3px;
      top: 3px;
      padding: 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
