@import '../../assets/styles';

.pricing-form {
    width: 100%;
    background-color: $color-background-gray;

    &__header {
        display: flex;
        gap: 16px;
        margin: 0 8px 46px 8px;
    }

    &__subtitle {
        color: $color-secondary;
        font-size: 24px;
        font-weight: 300;
        line-height: 52px;
        text-transform: uppercase;
        text-align: center;
    }

    &__selected-data {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 300px;
        padding: 16px;
        border-radius: 15px;
        display: flex;
        background-color: $color-primary;
        color: $color-white;
        font-size: 18px;
        font-weight: 700;
        line-height: 39px;
    }

    &__promo-code {
        display: flex;
        flex-direction: column;
        .promo-button {
          margin: 0 11px;
          cursor: pointer;
          max-height: 39px;
          padding: 8px 22px 10px;
          border-radius: 4px;
          background-color: #4a4a49;
          color: #ffffff;
          font-size: 16px;
          font-weight: 400;
          white-space: nowrap;
          &:hover {
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
          }
        }
        &-top {
          display: flex;
          align-items: center;
          .textfield {
            width: 250px;
          }
        }
        &-bottom {
          .promo-code-bottom {
            padding: 0 11px;
          }
          .promo-code-bottom__negative {
            color: #ca3434;
          }
          .promo-code-bottom__positive {
            color: #007c40;
            &--price {
              font-weight: 600;
            }
          }
        }
      }

    &__group {
        margin-bottom: 45px;
        display: flex;
        flex-wrap: wrap;

        &>* {
            width: 50%;
            padding: 0 11px;
            margin-bottom: 26px;
        }
    }

    &__info-group {
        margin-bottom: 16px;
        font-size: 14px;
        color: #4a4a49;
        .info-group__title{
            margin-bottom: 10px;
        }
        .info-group__mail,.info-group__phone{
            color: #4a4a49;
            white-space: nowrap;
        }
    }

    &__checkbox-group {
        margin-left: 8px;
    }

    &__label {
        max-width: 1224px;
        margin: 0 auto;
        padding: 0 11px;
        color: #007c40;
        font-size: 18px;
        font-weight: 700;
        line-height: 39px;
    }

    &__cta {
        margin: 0 auto;
        max-width: 1224px;
    }

    &__submit {
        height: 70px;
        padding: 24px 36px;
        border-radius: 20px;
        margin: 40px 0 21px 11px;
        background-color: $color-primary;
        cursor: pointer;
        color: $color-white;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
    }
    .input-boxes {
        display: flex;
        flex-direction: column;
        gap: 8px;
        color: #67757c;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;

        &__container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    
    }

    .error{
        color: $error-red;
    }
    
    .item {
        width: 25px !important;
        height: 40px;
        border: 1px solid #d4d4d4;
        text-align: center;
        text-transform: capitalize;
    
        &.error{
            border: 1px solid $error-red;
    
        }
    }
    .item:focus-visible {
        border: 2px solid $color-primary;
        outline: none;
    }

    @media screen and (max-width: 1024px) {
        &__promo-code {
          .promo-button {
            margin-bottom: 26px;
          }
        }
        .pricing-form__header {
          flex-direction: column;
          width: fit-content;
        }
        .pricing-form__promo-code {
          &-top {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }

    @media screen and (max-width:$mobile) {
        &__group {

            &>* {
                width: 100%;
            }
        }

        &__selected-data {
            min-width: 150px;
        }

        &__header {
            flex-direction: column;
        }

        &__cta {
            display: flex;
            justify-content: center;
        }

        &__submit {
            margin: 40px 0 21px 0;
        }
        .input-boxes {   
            &__container {
                display: flex;
                justify-content: flex-start;
                gap: 4px;
                flex-wrap: wrap;
            }
        
        }
    }
}