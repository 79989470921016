@import '../../assets/styles';

.header {
  overflow: hidden;
  height: 570px;
  position: relative;

  .container {
    height: 100%;
    display: flex;
  }

  &__background-container {
    width: 100%;
    height: 100px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    .header__background-overlay {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      min-width: 100%;
      min-height: 100%;
    }
    .header__background-image {
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      transition: 1.5s;
    }
    .active {
      opacity: 1;
    }
  }

  &__wrapper {
    background-color: rgba(#1a6e3c, 0.9);
    color: #ffffff;
    padding: 37px 28px 24px;
    max-width: 500px;
    margin-top: auto;

    .wrapper__title {
      text-transform: uppercase;
      margin-bottom: 24px;
      font-size: 36px;
      line-height: 39px;
    }

    .wrapper__list {
      margin-bottom: 29px;
      padding-left: 32px;

      li {
        position: relative;
        margin-bottom: 24px;
        font-size: 19px;
        line-height: 22px;
        font-weight: 300;

        &:before {
          position: absolute;
          content: '';
          width: 13px;
          height: 13px;
          border: 3px solid #ffffff;
          border-radius: 50%;
          transform: translateX(-100%);
          left: -12px;
          top: 5px;
        }
      }

      ::marker {
        content: '';
      }
    }

    .wrapper__bottom {
      display: flex;
      justify-content: center;

      .button {
        background-color: $color-secondary;
      }

      &__text {
        font-size: 13px;
        line-height: 15px;
        font-weight: 300;
        color: #ffffff;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .header {
    height: initial;

    &__wrapper {
      margin-top: 70px;
      padding: 30px 16px 20px;

      .wrapper__title {
        font-size: 24px;
        line-height: 1.2;
      }

      .wrapper__list {
        li {
          font-size: 14px;
          line-height: 1.4;
          margin-bottom: 12px;
        }
      }

      .wrapper__bottom {
        .button {
          height: initial;
          padding: 16px 32px;
        }

        &__text {
          text-align: center;
        }
      }
    }

    &__background-container {
      .header__background-overlay,
      .header__background-image {
        left: -500px;
      }
    }
  }
}
