@import './assets/styles';

.App {
  font-family: 'circe', sans-serif;
}

// global styles
.title {
  color: $color-secondary;
  font-size: 48px;
  font-weight: 300;
  line-height: 52px;
  text-transform: uppercase;
  text-align: center;

  @media screen and (max-width: $tablet) {
    font-size: 50px;
    line-height: 56px;
  }

  @media screen and (max-width: $mobile) {
    font-size: 24px;
    line-height: 1.1;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
  width: 100%;
  @media screen and (max-width: $mobile) {
    padding: 0 20px;
  }
}

.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 52px;
  width: fit-content;
  max-width: 224px;
  padding: 36px;
  border-radius: 16px;
  background-color: $color-primary;

  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-white;
  line-height: 24px;
  white-space: nowrap;

  transition-property: box-shadow, transform, border;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  user-select: none;

  @media screen and (max-width: $mobile) {
    font-size: 14px;
    line-height: 1.5;
    border-radius: 10px;
  }

  &:hover {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  }
}

.block-scroll {
  overflow: hidden;
  touch-action: none;
}
