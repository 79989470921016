html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

a {
  text-decoration: unset;
}

ol,
ul,
dl {
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-bottom: 0;
  margin-top: 0;
}

p {
  margin-bottom: 0;
  margin-top: 0;
}

button {
  border: none;
  padding: 0;
  background: transparent;
  font-family: 'circe';
}

input {
  -webkit-appearance: none;
}
