@import '../../assets/styles';

.order-pricing-buttons {
  padding: 60px 0;
  background-color: #f2f2f2;
  &__wrapper {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .button-container {
      width: 225px;
      margin: 0 42px;

      .subtitle {
        margin-top: 16px;
        display: flex;
        &__icon {
          width: 40px;
          height: 40px;
          margin-left: 5px;
          margin-right: 10px;
        }
        &__text {
          color: #4a4a49;
          font-size: 15px;
          margin-top: 4px;
        }
      }
    }
  }
  .uploading__fail {
    text-align: center;
    margin: 16px 0;
    color: $error-red;
  }

  &__bottom-text {
    margin: 24px auto 0;
    width: fit-content;
    color: #4a4a49;
    font-size: 12px;

    .bottom-text__phone,
    .bottom-text__mail {
      color: blue;
      white-space: nowrap;
    }
  }
}

@media screen and (max-width: $mobile) {
  .order-pricing-buttons {
    &__wrapper {
      flex-direction: column;
      gap: 40px;
    }
  }
}
