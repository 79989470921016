@import "../../assets/styles/index.scss";

.response-info {
    background-color: $color-background-gray;

    &__main {
        display: flex;
        justify-content: center;
        width: 60%;
        padding: 60px 0;
        margin: auto;
    }

    &__box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 12px;
        border-radius: 16px;
        color: white;
        text-align: center;

        &.success {
            color: green;

            .lock-info {
                display: flex;
                align-items: center;
                gap: 8px;
                font-size: 15px;
                font-weight: 400;
                line-height: 34px;

                &__img {
                    width: 22px;
                    height: 20px;
                }
            }
        }

        &.error {
            color: $error-red;

            .button {
                background-color: $error-red;
            }
        }
    }
}