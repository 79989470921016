@import '../../assets/styles';

.navbar {
  background-color: $color-primary;
  .navbar__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    .navbar__logo-link {
      display: flex;
      height: 45px;
      min-width: 147px; //for Firexfox fix
    }

    .navbar__menu {
      .navbar__menu-container {
        display: flex;
        .navbar__menu-item {
          cursor: pointer;
          margin-right: 20px;
          color: $color-white;
          list-style: none;
          text-transform: uppercase;
        }
      }
    }

    .navbar__order-button {
      cursor: pointer;
      max-height: 39px;
      padding: 8px 22px 10px;
      border-radius: 4px;
      background-color: $color-secondary;
      color: $color-white;
      font-size: 16px;
      font-weight: 400;
      white-space: nowrap;

      &:hover {
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
      }
    }

    .navbar__hamburger-icon {
      cursor: pointer;
      display: none;
      & > * {
        display: block;
        width: 28px;
        height: 4px;
        background-color: $color-white;

        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .navbar {
    .navbar__container {
      position: relative;
      .navbar__menu {
        position: absolute;
        z-index: 2;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: $color-secondary;
        .navbar__menu-container {
          display: flex;
          flex-direction: column;
          .navbar__menu-item {
            opacity: 0;
            visibility: hidden;
            height: 0px;
            padding: 0 30px;
            margin-right: 0;
            color: $color-white;
            text-transform: uppercase;
            transition: all 0.3s ease;

            &:hover,
            &:active {
              background-color: $color-dark-gray;
            }
            .navbar__menu-item-content {
              display: flex;
              align-items: center;
              height: 55px;
            }
          }
        }
      }
      .navbar__menu--open {
        .navbar__menu-container {
          .navbar__menu-item {
            height: 55px;
            opacity: 1;
            visibility: visible;
          }
        }
      }
      .navbar__order-button {
        display: none;
      }
      .navbar__hamburger-icon {
        display: block;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .navbar {
    .navbar__container {
      padding: 15px 20px;
    }
  }
}
