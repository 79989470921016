@import '../../assets/styles';

.selector {
    width: 100%;
    background: $color-background-gray;
    padding: 60px 0;

    &__title {
        padding: 20px 0;
        color: $color-secondary;
        font-size: 48px;
        font-weight: 300;
        line-height: 52px;
        text-transform: uppercase;
        text-align: center;
    }

    &__label {
        padding: 5px;
        text-transform: uppercase;
        text-align: center;
        color: $color-secondary;

        &__selected-value {
            color: $color-primary;
        }
    }
}