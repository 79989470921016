@import '../../assets/styles';

.textfield {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 26px;
    padding: 0 11px;

    &__label {
        color: #67757c;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
    }

    &__input {
        align-items: center;
        display: flex;
        padding: 0 12px;
        width: 100%;
        background-color: #fff;
        border: 1px solid #d4d4d4;
        border-radius: 4px;
        color: $color-secondary;
        font-size: 15px;
        font-weight: 400;
        height: 38px;
        line-height: 24px;

        &:focus {
            outline: none;
        }

        &.error {
            border: 1px solid $error-red;
            color: $error-red;
            background-image: url(../../assets/icons/errorValidateIcon.svg);
            background-position: right 10px center;
            background-repeat: no-repeat;
            background-size: 18px 18px;
        }
    }
}

.checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 30px;
    position: relative;
    cursor: pointer;
    width: fit-content;
    color: #67757c;
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    user-select: none;

    @media screen and ($mobile) {
        font-size: 13px;
        line-height: 1.3;
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 2px;
        width: 20px;
        height: 20px;
        border: 1px solid $color-dark-gray;
        cursor: pointer;
        border-radius: 4px;
        background-color: #FFFFFF;
    }

    input {
        position: absolute;
        left: -4px;
        top: -3px;
        width: 1px;
        height: 1px;
        opacity: 0;
        z-index: 99;

        &:checked {
            &+.checked {
                display: block;
            }

            &~ :after {
                background-color: $color-primary;
                border-color: $color-primary;
            }
        }
    }

    .checked {
        content: "";
        position: absolute;
        display: none;
        left: 7px;
        top: 6px;
        width: 7px;
        height: 10px;
        border: solid $color-primary;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        cursor: pointer;
        z-index: 1;
    }

    a {
        font-weight: bold;
        text-decoration: underline;
        color: $color-primary;
    }
}

p.error {
    margin: 0;
    color: $error-red;
    font-size: 13px;
    line-height: 24px;
}