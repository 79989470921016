@import '../../assets/styles';

.collapsible {
    position: relative;
    max-width: 100%;
    margin: 16px 0 16px 10px;

    &__title {
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 10px 0;
        color: $color-primary;
        font-size: 18px;
        font-weight: 400;
        transition: transform 300ms ease;
        text-align: start;

        &:before {
            content: url('../../assets/icons/arrow.svg');
            position: absolute;
            left: -35px;
            transition: transform 300ms ease;
            text-align: center;
            width: 40px;
        }

        &.expanded {
            &:before {
                transform: rotate(90deg);
            }
        }
    }

    &__text {
        max-width: 536px;
        color: $color-secondary;

        &.collapse {
            overflow: hidden;
            transition: height 0.2s ease-in-out;

            &.show-description {
                overflow: visible;
            }
        }
    }
}