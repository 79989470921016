@import '../../assets/styles';

.footer {
  background-color: $color-strong-dark-gray;
  padding: 28px 0;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      color: $color-white;
      text-transform: uppercase;
      font-style: normal;
      font-size: 15px;
    }
  }
  &__left-section {
    display: flex;
    align-items: center;
  }

  &__logo {
    margin-right: 56px;
  }
}

@media screen and (max-width: $tablet) {
  .footer {
    text-align: center;
    padding: 45px 0;

    &__content {
      flex-direction: column;
    }

    &__left-section {
      flex-direction: column;
      margin-bottom: 25px;
    }

    &__logo {
      margin-right: 0;
      margin-bottom: 50px;
      min-width: 200px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .footer {
    &__logo {
      margin-bottom: 40px;
    }
  }
}
