@import '../../assets/styles';

.use-expert-services {
  padding: 48px 0;

  &__header {
    margin-bottom: 53px;
    &--green {
      color: $color-primary;
    }
  }

  .items {
    display: flex;
    justify-content: space-around;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__img {
        width: 120px;
      }
      &__text {
        margin-top: 30px;
        width: 240px;
        text-align: center;
        color: $color-secondary;
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .use-expert-services {
    padding: 60px 0 50px;

    .items {
      flex-direction: column;
      gap: 60px;

      .item {
        &__text {
          font-size: 27px;
          line-height: 36px;
          width: 400px;
          margin-top: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .use-expert-services {
    &__header {
      margin-bottom: 32px;
    }

    .items {
      gap: 40px;

      .item {
        &__text {
          font-size: 16px;
          line-height: 1.3;
          max-width: 230px;
          width: initial;
          margin-top: 28px;
        }
      }
    }
  }
}
