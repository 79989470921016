@import '../../assets/styles';

.q-and-a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    min-height: 470px;
    padding: 24px 0;
    background-image: url(../../assets/images/questions-and-answers-bg.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;

    li{
        margin-left: 20px;
    }

    &__title {
        text-align: left;
    }

    &__question-section {
        max-width: 100%;
        height: auto;
    }
    .procedures-title{
        font-weight: 600;
        font-size: 18px;
    }
    .procedures-tiles{
        display: flex;
        flex-wrap: wrap;
    }
    .procedures-image{
        width: 100%;
    }
    .procedures-item{
        flex-basis: 23%;
        margin: 5px;
        padding: 3px;
        border: 1px solid $color-primary;
        border-radius: 4px;
        font-size: 12px;
    }
    .procedures-text{
        height: 100%;
        border: 1px solid black;
    }
}

@media screen and (max-width:$tablet) {
    .q-and-a {
        min-height: initial;
        padding: 50px 0 70px;
        background-image: url("../../assets/images/bg-faq-mobile.jpg");
        background-position: right top;

        &__title {
            text-align: center;
        }
        .button{
            margin: auto;
        }
    }
}
@media screen and (max-width:$mobile) {
    .q-and-a {
        min-height: initial;
        padding: 50px 0 70px;
        background-image: url("../../assets/images/bg-faq-mobile.jpg");
        background-position: right top;
        &__title {
            text-align: center;
        }
        .procedures-item{
            hyphens: auto;
            overflow: hidden;
        }
        .button{
            margin: auto;
        }
    }
}